(function () {
  'use strict';

  class GroupsCtrl {
    constructor(Groups, $scope, $rootScope, Utils, Classrooms, $filter, CurrentModuleFlow) {
      let vm = this;
      vm.ctrlName = 'GroupsCtrl';
      vm.Groups = Groups;
      vm.Utils = Utils;
      vm.Classrooms = Classrooms;
      vm.CurrentModuleFlow = CurrentModuleFlow;
      vm.$scope = $scope;
      vm.$filter = $filter;
      vm.$rootScope = $rootScope;
      vm.init();
    }
    init() {
      let vm = this;
      if (vm.Utils.roleCheck(['Teacher'])) {
        if (vm.$rootScope.classroom_id) {
          vm.classroomView();
        } else {
          vm.Groups.myGroups()
            .then((data)=> {
              vm.my_groups = data;
            });
        }
        vm.Classrooms.myClassrooms()
          .then((data)=> {
            vm.classroomsIndex = data;
          });
      }
    }
    classroomView() {
      let vm = this;
      if (!vm.$rootScope.classroom_id) {
        return false;
      }
      vm.Classrooms.classroomView({id: vm.$rootScope.classroom_id})
        .then((data)=> {
          vm.my_groups = {
            documents: data.groups
          };
        });
    }
    openMenu($mdMenu, ev) {
      $mdMenu.open(ev);
    }
  }

  /**
   * @ngdoc object
   * @name dashboard.groups.controller:GroupsCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard.groups')
    .controller('GroupsCtrl', GroupsCtrl);
}());
